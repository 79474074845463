import React from 'react';
import { Button, Col, Form, Input, Modal, Row, Space } from 'antd';
import { observer } from 'mobx-react';
import type WhiteListEditModel from './whiteListEditModel';

// 新增或编辑需求白名单
const WhiteListEdit = observer(({ store }: { store: WhiteListEditModel }) => {
  if (!store) {
    return null;
  }
  const { visible, saveLoading, id, onClose, onSave, setRef } = store;
  return (
    <Modal
      centered
      footer={
        <Space>
          <Button onClick={onClose}>取消</Button>
          <Button
            loading={saveLoading}
            onClick={onSave}
            type="primary"
          >
            保存
          </Button>
        </Space>
      }
      maskClosable={false}
      onCancel={onClose}
      open={visible}
      title={id ? '编辑白名单' : '新增白名单'}
    >
      <Form ref={setRef}>
        <Form.Item
          label="手机号"
          name="mobile"
          rules={[
            {
              required: true,
              pattern: /^1[3456789]\d{9}$/,
              message: '请输入正确的手机号',
            },
          ]}
          validateTrigger="onBlur"
        >
          <Input
            autoComplete="off"
            placeholder="请输入手机号"
            maxLength={11}
            disabled={Boolean(id)}
          />
        </Form.Item>
        <Form.Item
          label="备注"
          name="remark"
        >
          <Input.TextArea
            autoComplete="off"
            placeholder="请输入备注"
            maxLength={100}
            showCount
            autoSize={{
              minRows: 3,
              maxRows: 6,
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
});

export default WhiteListEdit;
