import type { FormInstance } from 'antd';
import { message } from 'antd';
import type { BaseData } from '../../../utils';
import { request } from '../../../utils';
import { action, observable } from 'mobx';
import { API } from '../api';

interface resultValue<T> {
  code: number;
  data?: T;
  msg?: string;
}

export default class WhiteListEditModel {
  @observable
  public visible = false;

  @observable
  public type = 'add';

  @observable
  public saveLoading = false; // 保存状态

  @observable public ref: FormInstance;

  @observable public id = null; // 用户id

  @observable public parent = null;

  constructor({ parent }) {
    this.parent = parent;
  }

  @action
  public setRef = (ref: FormInstance): void => {
    this.ref = ref;
  };

  @action
  public onShow = (data?: any, type = 'add') => {
    this.type = type;
    this.visible = true;
    if (type !== 'add') {
      setTimeout(() => {
        this?.ref?.setFieldsValue(data);
      });
      this.id = data.id;
    }
  };

  @action
  public onClose = () => {
    this.id = '';
    this.ref.resetFields();
    this.visible = false;
  };

  @action
  public onSave = async () => {
    const isValidated = await this.ref.validateFields();
    if (!isValidated) {
      return;
    }
    this.saveLoading = true;
    const values = this.ref.getFieldsValue();
    let postParams = { ...values };
    if (this.type === 'edit') {
      postParams = {
        ...postParams,
        id: this.id,
      };
    }
    const apiUrl = this.type === 'add' ? API.add : API.edit;
    request<resultValue<any>>({
      url: apiUrl,
      method: 'POST',
      data: { ...postParams },
    })
      .then((result) => {
        message.success(result.msg || '操作成功');
        this.ref.resetFields();
        this.onClose();
        this.parent?.pageStore.grid.onQuery();
      })
      .finally(() => {
        this.saveLoading = false;
      });
  };
}
