import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import { SearchListStructure } from '../../utils/searchListStructure';
import Model from './model';
import WhiteListEditModal from './modal/whiteListEdit';

const WhiteListManagement = observer(() => {
  const store = useMemo(() => new Model(), []);
  const { pageStore, editStore } = store;
  return (
    <div>
      <SearchListStructure store={pageStore} />
      <WhiteListEditModal store={editStore} />
    </div>
  );
});

export default WhiteListManagement;
