import React from 'react';
import _ from 'lodash';
import { Button, Modal, message, Tooltip } from 'antd';
import { observable } from 'mobx';
import { request, SearchListModal, RenderByPermission } from '../../utils';
import type { BaseData, IMainSubStructureModel, MainSubStructureModel, NormalProgrammeParams } from '../../utils';
import { API } from './api';
import dayjs from 'dayjs';
import WhiteListEditModel from './modal/whiteListEditModel';

export default class Model {
  constructor() {
    this.pageStore.grid.onQuery();
  }

  @observable
  public total = 0;

  public editStore = new WhiteListEditModel({ parent: this });

  public filterSet: Partial<NormalProgrammeParams> = {
    filterItems: [
      {
        type: 'input',
        label: '手机号',
        field: 'mobile',
      },
      {
        type: 'input',
        label: '备注',
        field: 'remark',
      },
      {
        type: 'dateRange',
        field: 'createTime',
        label: '创建时间',
        format: 'YYYY-MM-DD',
        formatParams: 'YYYY-MM-DD',
        span: 6,
      },
    ],
  };

  public grid: IMainSubStructureModel = {
    buttons: [
      {
        text: '新增',
        permissionId: '2',
        handleClick: () => {
          this.editStore.onShow();
        },
      },
    ],
    pageId: new URLSearchParams(window.location.search)?.get('pageId'),
    grid: {
      columns: [
        {
          key: 'operate',
          name: '操作',
          formatter: ({ row }) => {
            const pageId = new URLSearchParams(window.location.search)?.get('pageId');
            return (
              <div>
                <RenderByPermission permissionId={`${pageId}_4`}>
                  <Button
                    onClick={() => this.onDelete(row)}
                    type="link"
                  >
                    删除
                  </Button>
                </RenderByPermission>
                <RenderByPermission permissionId={`${pageId}_3`}>
                  <Button
                    onClick={() => {
                      this.editStore.onShow(row, 'edit');
                    }}
                    type="link"
                  >
                    编辑
                  </Button>
                </RenderByPermission>
              </div>
            );
          },
        },
        {
          key: 'mobile',
          name: '手机号',
        },
        {
          key: 'createTime',
          name: '创建时间',
          formatter: ({ row }) => (row.createTime ? dayjs(row.createTime).format('YYYY-MM-DD HH:mm:ss') : ''),
        },
        {
          key: 'remark',
          name: '备注',
          formatter: ({ row }) =>
            row?.remark?.length > 40 ? (
              <Tooltip title={row.remark}>
                <span style={{ cursor: 'pointer' }}>{row.remark}</span>
              </Tooltip>
            ) : (
              row?.remark
            ),
        },
      ].map((item) => ({
        resizable: true,
        sortable: false,
        ...item,
      })),
      rows: [],
      primaryKeyField: 'id',
      sortByLocal: false,
      showCheckBox: false,
      showEmpty: true,
      showSelectedTotal: false,
    },
    api: {
      onQuery: (params) => {
        const { filterParams, pageNum, pageSize } = params;
        const postParams = {
          ...filterParams,
          pageNum,
          pageSize,
        };
        if (postParams.createTime) {
          postParams.startTime = postParams.createTime.split(',')[0];
          postParams.endTime = postParams.createTime.split(',')[1];
          delete postParams.createTime;
        }
        return request<BaseData<any>>({
          url: API.fetchList,
          method: 'POST',
          data: postParams,
        });
      },
      callbackAfterQuery: (_this: MainSubStructureModel) => {
        this.total = _this.gridModel.total;
      },
    },
  };

  public pageStore = new SearchListModal({
    programme: this.filterSet,
    grid: this.grid,
  });

  // 删除
  public onDelete = (row: any) => {
    Modal.confirm({
      title: '删除提示',
      content: `确定要删除【${row.mobile}】吗？`,
      okText: '确认',
      cancelText: '取消',
      onOk: async () => {
        const result: BaseData<string> = await request({
          url: `${API.delete}/${row.id}`,
          method: 'POST',
        });
        message.success(result.data || '操作成功');
        this?.pageStore?.grid?.onQuery();
      },
    });
  };
}
